import {
  Channel,
  Context,
  groceriesApiBffURL,
} from "@whitelabel-webapp/shared/config";
import { CustomVariable, Money } from "@whitelabel-webapp/shared/models";
import axios, { AxiosInstance } from "axios";
import isEmpty from "lodash.isempty";

import { MerchantShifts } from "../shifts";
import { DeliveredBy, DeliveryMethodResponse } from "../deliveryMethod";
import { deliveredMethodsSorter } from "./constants";
import {
  ExperimentsConfigs,
  Features,
  GraphQlResponse,
  MerchantAddressResponse,
  MerchantConfigs,
  MerchantConfigsResponse,
  MerchantResponse,
  MerchantWhitelabelConfigs,
  Resource,
  Test,
} from "./types";
import { CHAIN_LOGO_URL } from "@whitelabel-webapp/shared/constants";

export class Merchant {
  static bffClient: AxiosInstance;

  static initBffClient(): void {
    Merchant.bffClient = axios.create({
      baseURL: groceriesApiBffURL,
    });
  }

  static async getMerchant(
    path: string,
    brandHeader?: string,
  ): Promise<MerchantResponse> {
    if (!Merchant.bffClient) {
      Merchant.initBffClient();
    }

    const headers = {
      ...(brandHeader && {
        "x-cardapiodigital-brand": brandHeader,
      }),
    };
    const { data } = await Merchant.bffClient.get<MerchantResponse>(
      `/v2/merchant/${path}`,
      { headers },
    );

    return data;
  }

  static async getMerchantConfigs(uuid: string) {
    if (!Merchant.bffClient) {
      Merchant.initBffClient();
    }

    const { data } = await Merchant.bffClient.get<MerchantConfigsResponse>(
      `/v2/merchant/${uuid}/configs`,
    );

    return data;
  }

  static async getDeliveryMethods(
    uuid: string,
    latitude: number,
    longitude: number,
  ) {
    if (!Merchant.bffClient) {
      Merchant.initBffClient();
    }

    const {
      data: {
        data: {
          merchant: { deliveryMethods },
        },
      },
    } = await Merchant.bffClient.post<GraphQlResponse>(
      `/v1/merchant-info/graphql`,
      {
        query:
          "query ($merchantId: String!) { merchant (merchantId: $merchantId, required: true) { deliveryMethods { id mode title subtitle type value priority deliveredBy minTime maxTime state schedule { now shifts { dayOfWeek endTime interval startTime } timeSlots { availableLoad date endDateTime endTime id isAvailable originalPrice price startDateTime startTime } } } } }",
        variables: {
          merchantId: uuid,
        },
      },
      {
        params: {
          latitude: latitude,
          longitude: longitude,
        },
      },
    );

    return deliveryMethods as DeliveryMethodResponse[];
  }

  static fromApi(rawMerchant: MerchantResponse) {
    const chainSlug = rawMerchant.merchantWhitelabelConfigs.chain.slug;
    const query = `${chainSlug}/${rawMerchant.uuid}`;

    return new Merchant(
      rawMerchant.uuid,
      rawMerchant.name,
      rawMerchant.description,
      rawMerchant.available,
      rawMerchant.resources,
      rawMerchant.phoneIf,
      rawMerchant.address,
      MerchantShifts.fromApi(rawMerchant.shifts),
      new Money(rawMerchant.minimumOrderValue),
      rawMerchant.configs?.nationalIdentificationNumberRequired,
      rawMerchant.features,
      rawMerchant.merchantConfigs,
      rawMerchant.merchantConfigs.experiments,
      rawMerchant.test,
      query,
      rawMerchant.merchantWhitelabelConfigs,
      rawMerchant.externalLinks,
      rawMerchant.flags,
    );
  }

  constructor(
    public id: string,
    public name: string,
    public description: string,
    public available: boolean,
    public resources: Resource[],
    public phone: string,
    public address: MerchantAddressResponse,
    public shifts: MerchantShifts,
    public minimumOrderValue: Money,
    public isDocumentRequired: boolean,
    public features: Features[],
    public merchantConfigs: MerchantConfigs = {
      charge: "",
      pixPaymentEnabled: false,
      onlineCardsPaymentEnabled: false,
      shouldRunCatalogPlusIconExperiment: false,
      userOpinionSurveyEnabled: false,
      indoorStoreType: "",
      isOrderRatingForMerchantEnabled: false,
      hasItemObservation: false,
      headerRating: "",
      isWhitelabelTrackingEnabled: false,
    },
    public experimentsConfigs: ExperimentsConfigs = {
      catalogPlusIcon: false,
      changeHomeButtonLabel: false,
      bagUserGuide: false,
      noAddresButtonRedirect: false,
      addItemRedirectCheckoutDetails: false,
      addAddressFlowAutocompleteImprovements: false,
      addAddressFlowPinStep: true,
    },
    public test: Test,
    /**
     * Query string used to find this merchant. It could be a UUID or a string (custom-path).
     */
    public query: string,
    public merchantWhitelabelConfigs: MerchantWhitelabelConfigs,
    public externalLinks?: Record<string, CustomVariable>,
    public flags?: Record<string, boolean>,
  ) {}

  async getAvailability() {
    const { available } = await Merchant.getMerchant(this.id);
    this.available = available;
    return available;
  }

  async getDeliveryMethod(latitude: number, longitude: number) {
    const deliveryMethods = await Merchant.getDeliveryMethods(
      this.id,
      latitude,
      longitude,
    );

    if (!deliveryMethods?.length) return;

    return deliveryMethods
      .sort((a, b) => {
        const aOrder = deliveredMethodsSorter[a.deliveredBy as DeliveredBy];
        const bOrder = deliveredMethodsSorter[b.deliveredBy as DeliveredBy];
        return aOrder - bOrder;
      })
      .find((item) => item.mode === "DELIVERY");
  }

  async getTakeoutMethod() {
    const deliveryMethods = await Merchant.getDeliveryMethods(
      this.id,
      this.address.latitude,
      this.address.longitude,
    );

    if (!deliveryMethods?.length) return;

    return deliveryMethods.find((item) => item.mode === "TAKEOUT");
  }

  getStreetAddress() {
    const address = this.address;
    const hasStreetComplement = Boolean(address.streetCompl);
    return hasStreetComplement
      ? `${address.streetName}, ${address.streetNumber}, ${address.streetCompl}`
      : `${address.streetName}, ${address.streetNumber}`;
  }

  getNeighborhoodAddress() {
    const address = this.address;
    return `${address.district}, ${address.city} - ${address.state}`;
  }

  hasSchedulingFeature() {
    return Boolean(this.features.find((feature) => feature === "SCHEDULING"));
  }

  hasTakeoutFeature() {
    return Boolean(this.features.find((feature) => feature === "TAKEOUT"));
  }

  hasDeliveryFeature() {
    return Boolean(this.features.find((feature) => feature === "DELIVERY"));
  }

  hasIndividualCharge() {
    return Boolean(this.merchantConfigs.charge === "INDIVIDUAL");
  }

  isTest() {
    return this.test === "TEST";
  }

  hasIndoorStoreType() {
    return Boolean(this.merchantConfigs.indoorStoreType);
  }

  isGloboIndoorStore() {
    return Boolean(
      this.merchantConfigs.indoorStoreType &&
        this.merchantConfigs.indoorStoreType === "GLOBO",
    );
  }

  isRestaruantIndoorStore() {
    return Boolean(
      this.merchantConfigs.indoorStoreType &&
        this.merchantConfigs.indoorStoreType === "RESTAURANT",
    );
  }

  isHotelIndoorStore() {
    return Boolean(
      this.merchantConfigs.indoorStoreType &&
        this.merchantConfigs.indoorStoreType === "HOTEL",
    );
  }

  getFAQLink() {
    return this.externalLinks?.["FAQ"]?.url || "";
  }

  getExternalLinks() {
    if (isEmpty(this.externalLinks)) return;

    const commonExternalLinks = ["FAQ", "serviceTerms"];

    const externalLinksKeys = Array.from(
      Object.keys(this.externalLinks as Record<string, CustomVariable>),
    ).filter((key) => !commonExternalLinks.includes(key));

    const externalLinksValues = [];

    for (let index = 0; index <= externalLinksKeys.length; index++) {
      const item = (this.externalLinks as Record<string, CustomVariable>)[
        externalLinksKeys[index]
      ];

      if (item) externalLinksValues.push(item);
    }

    return externalLinksValues;
  }

  getBanners() {
    return (
      this.merchantWhitelabelConfigs.banners.filter((banner) => !banner.mini) ??
      []
    );
  }

  getMiniBanners() {
    return (
      this.merchantWhitelabelConfigs.banners.filter((banner) => banner.mini) ??
      []
    );
  }

  getChain() {
    return this.merchantWhitelabelConfigs.chain;
  }

  getLogoUrl() {
    return this.getChain().logoUrl
      ? `${CHAIN_LOGO_URL}/${this.getChain().logoUrl}`
      : "";
  }

  getSocialMediaLinks() {
    return this.getChain().links?.filter(link => link.type === "SOCIAL_MEDIA") || [];
  }

  getInstitutionalLink() {
    return this.getChain().links?.filter(link => link.type === "INSTITUTIONAL") || [];
  }

  getTenantId() {
    return `GRW_${this.getChain().id}`;
  }
}
